import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { 
	faArrowUp, 
	faEdit, 
	faGlobe, 
	faPlayCircle, 
	faQuoteLeft, 
	faQuoteRight, 
	faSearch as faSearchLight, 
	faSpinner, 
	faSync as FaSyncLight, 
	faTimes as faTimesLight, 
	faTimesCircle, 
	faUser as faUserLight, 
} from '@fortawesome/pro-light-svg-icons';
import { 
	faChevronDown, 
	faChevronLeft as faChevronLeftRegular, 
	faChevronRight as faChevronRightRegular, 
	faChevronUp as faChevronUpSolid, 
	faDownload,
	faEnvelope,
	faEye,
	faFile, 
	faFileAlt,
	faFileArchive, 
	faFileAudio,
	faFileImage, 
	faFileInvoice,
	faFilePdf, 
	faFilePowerpoint, 
	faFileSpreadsheet, 
	faFileVideo, 
	faFileWord, 
	faPhoneAlt,
	faSearch as faSearchRegular, 
	faTimes as faTimesRegular, 
} from '@fortawesome/pro-regular-svg-icons';
import { 
	faCircle, 
	faLink, 
	faSearch as faSearchSolid, 
	faSpinner as faSpinnerSolid 
} from '@fortawesome/pro-solid-svg-icons';
import { 
	faCalendarAlt, 
	faChevronLeft, 
	faChevronRight, 
	faClock, 
} from '@fortawesome/pro-duotone-svg-icons';
import { 
	faFacebook, 
	faInstagram, 
	faLinkedinIn, 
	faMediumM, 
	faTwitter, 
	faTwitterSquare, 
	faWhatsapp, 
	faYoutube, 
} from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

library.add(
	faArrowUp,
	faCalendarAlt,
	faChevronDown,
	faChevronLeft,
	faChevronLeftRegular,
	faChevronRight,
	faChevronRightRegular,
	faChevronUpSolid,
	faCircle,
	faClock,
	faDownload,
	faEdit,
	faEnvelope,
	faEye,
	faFacebook,
	faFile,
	faFileAlt,
	faFileArchive,
	faFileAudio,
	faFileImage,
	faFileInvoice,
	faFilePdf,
	faFilePowerpoint,
	faFileSpreadsheet,
	faFileWord,
	faFileVideo,
	faGlobe,
	faInstagram,
	faLink,
	faLinkedinIn,
	faMediumM,
	faPhoneAlt,
	faPlayCircle,
	faQuoteLeft,
	faQuoteRight,
	faSearchLight,
	faSearchRegular,
	faSearchSolid,
	faSpinner,
	faSpinnerSolid,
	FaSyncLight,
	faTimesCircle,
	faTimesLight,
	faTimesRegular,
	faTwitter,
	faTwitterSquare,
	faUserLight,
	faWhatsapp,
	faYoutube
);
dom.i2svg();
dom.watch();